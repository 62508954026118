import React, { ReactNode } from 'react'

import { MDXProvider } from '@mdx-js/react'
import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

import SEO from './SEO'
import { BaseText, H1, H2, H3, H4, P, getBaseStyle } from './Text'

/**
 * All global styles go here
 */
export const GlobalStyle = createGlobalStyle`
  ${reset}
  html, body {
    height: 100%; // Necessary for making the page full
    font-family: SuisseIntl-Regular, sans-serif;
    font-variant-numeric: tabular-nums lining-nums; // New browser
    font-feature-settings: 'tnum' on, 'lnum' on; // Support for old browser
    --themeBG: white;
    --themeBGrgba: 255, 255, 255;
    --themeColor: black;
    scrollbar-width: thin;
    scrollbar-color: var(--themeColor) var(--themeBG);
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: var(--themeBG);
    }
    ::-webkit-scrollbar-thumb {
        background: var(--themeColor);
      }
    *, *:before, *:after {
        -webkit-box-sizing: border-box; 
        -moz-box-sizing: border-box; 
        box-sizing: border-box;
    }
  }
  html, body.dark {
    --themeBG: black;
    --themeBGrgba: 0, 0, 0;
    --themeColor: white;
  }

  ol {
    list-style-type: decimal;
    // list-style-position: inside;
    margin-left: 40px;
  }

  body {
    color: var(--themeColor);
    background-color: var(--themeBG);
    transition: background-color 0.6s ease-out, color 0.6s ease-out;
  }
  div, ul, li, a {
    color: var(--themeColor);  
    background-color: var(--themeBG);
    transition: background-color 0.6s ease-out, color 0.6s ease-out;
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: var(--themeBG);
    }
    ::-webkit-scrollbar-thumb {
        background: var(--themeColor);
      }
  }
  a, ul, li {
    background-color: transparent;
    text-decoration: none;
  }
  button {
    padding: 0px;
  }
`

const components = {
    p: P,
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    h5: H4,
    h6: H4,
    thematicBreak: P,
    blockquote: P,
    ul: getBaseStyle('ul'),
    ol: getBaseStyle('ol'),
    li: getBaseStyle('li'),
    a: getBaseStyle('a'),
}

type AppProps = {
    children?: ReactNode
}

const App = ({ children }: AppProps) => (
    <MDXProvider components={components}>
        <GlobalStyle />
        <SEO />
        {children}
    </MDXProvider>
)

export default App
