import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { mq } from '../utils/presets'

const baseTextCss = css`
    font-family: SuisseIntl-Regular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 18px;
    ${mq.tablet} {
        font-family: SuisseIntl-Book, sans-serif;
        font-size: 28px;
        line-height: 32px;
    }
    ${(props) =>
        props.normalNumeric
            ? 'font-feature-settings: normal; font-variant-numeric: normal;'
            : ''};
}
`

// Regular
export const BaseText = styled.p`
    ${baseTextCss}
`

export const getBaseStyle = (element) => styled(P).attrs({ as: element })``

const tinyCss = css`
    ${baseTextCss}
    font-family: SuisseIntl-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 18px;
    ${mq.tablet} {
        font-family: SuisseIntl-Book, sans-serif;
        font-weight: normal;
        font-size: 28px;
        line-height: 32px;
    }
    ${(props) =>
        props.normalNumeric
            ? 'font-feature-settings: normal; font-variant-numeric: normal;'
            : ''};
`

// Medium
export const Tiny = styled.p`
    ${tinyCss}
`

const baseMediumCss = css`
    font-family: SuisseIntl-Medium, sans-serif;
    font-style: normal;
    ${(props) =>
        props.normalNumeric
            ? 'font-feature-settings: normal; font-variant-numeric: normal;'
            : ''};
`
const baseBookCss = css`
    font-family: SuisseIntl-Book, sans-serif;
    font-style: normal;
    ${(props) =>
        props.normalNumeric
            ? 'font-feature-settings: normal; font-variant-numeric: normal;'
            : ''};
`

const h1Css = css`
    ${baseMediumCss}
    font-size: 34px;
    line-height: 38px;
    ${mq.tablet} {
        font-size: 44px;
        line-height: 38px;
    }
`
export const H1 = styled.h1`
    ${h1Css}
`

const h2Css = css`
    ${baseBookCss}
    font-size: 28px;
    line-height: 30px;
    ${mq.tablet} {
        font-size: 34px;
        line-height: 38px;
    }
`
export const H2 = styled.h2`
    ${h2Css}
`

const h3Css = css`
    ${baseMediumCss}
    font-weight: 500;
    font-size: 17px;
    line-height: 18px;
    ${mq.tablet} {
        font-size: 34px;
        line-height: 38px;
    }
`
export const H3 = styled.h3`
    ${h3Css}
`

const h4Css = css`
    ${baseBookCss}
    font-size: 17px;
    line-height: 18px;
    ${mq.tablet} {
        font-size: 34px;
        line-height: 38px;
    }
`
export const H4 = styled.h4`
    ${h4Css}
`

const pCss = css`
    ${baseTextCss}
    font-feature-settings: 'onum' on, 'pnum' on;
`

export const P = styled.p`
    ${pCss}
`

const artTypeMapping = {
    tiny: tinyCss,
    h1: h1Css,
    h2: h2Css,
    h3: h3Css,
    h4: h4Css,
    p: pCss,
}

const typeMapping = {
    tiny: Tiny,
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    p: P,
    default: BaseText,
}

const getArtDirectedText = (type) => {
    return artTypeMapping[type]
}

export const ArtDirectedText = styled.h1`
    ${(props) => getArtDirectedText(props.smart)}
    ${mq.tablet} {
        ${(props) => getArtDirectedText(props.tablet)}
    }
    ${mq.laptop} {
        ${(props) => getArtDirectedText(props.laptop)}
    }
`

type Type = 'tiny' | 'h1' | 'h2' | 'h3' | 'h4' | 'p'

type TextProps = {
    as?: React.ElementType
    type?: Type | Type[]
    children: ReactNode
    normalNumeric?: Boolean
}

const Text = ({ type, ...otherProps }: TextProps) => {
    if (type && type.length === 3) {
        return (
            <ArtDirectedText
                laptop={type[2]}
                tablet={type[1]}
                smart={type[0]}
                {...otherProps}
            />
        )
    }
    const Component = typeMapping[type as Type] || typeMapping['default']
    return <Component {...otherProps} />
}

export default Text
