import React from 'react'

import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'

type Keyword = 'blog' | 'personal site'

interface SEOProps {
    lang?: 'en' | 'de'
    meta?: any[]
    keywords?: Keyword[]
    title?: string
    description?: string
    image?: string
}

const SEO = ({
    title = '',
    description = '',
    lang = 'de',
    image,
}: SEOProps) => {
    const { pathname } = useLocation()
    const data = useStaticQuery(query)
    const { siteUrl, defaultImage, twitterUsername } = data.site.siteMetadata

    const {
        landingTitle,
        titleTemplate,
        seoDescription,
    } = data.infoPage.frontmatter

    const metaTitle = title || landingTitle
    const metaDescription = description || seoDescription
    const metaImage = `${siteUrl}${image || defaultImage}`
    const metaUrl = `${siteUrl}${pathname}`

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            defaultTitle={landingTitle}
            titleTemplate={titleTemplate}
        >
            {title && <title>{title}</title>}
            {metaUrl && <link rel="canonical" href={metaUrl} />}
            <meta name="description" content={metaDescription} />
            <meta name="image" content={metaImage} />

            <meta property="og:type" content="website" />
            {metaUrl && <meta property="og:url" content={metaUrl} />}
            {metaTitle && <meta property="og:title" content={metaTitle} />}
            <meta property="og:description" content={metaDescription} />
            {metaImage && <meta property="og:image" content={metaImage} />}
            <meta property="og:locale" content={lang} />

            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
                <meta name="twitter:creator" content={twitterUsername} />
            )}
            {metaImage && <meta property="twitter:image" content={metaImage} />}
            <meta name="apple-mobile-web-app-capable" content="yes" />

            <meta
                name="google-site-verification"
                content="SHQOBCR9DF_-iFZYt3hDfAjFlsBRYU4IsfwvzFbsm-c"
            />
        </Helmet>
    )
}

const query = graphql`
    query DefaultSEOQuery {
        infoPage: mdx(fileAbsolutePath: { glob: "**/pages/info.md" }) {
            frontmatter {
                landingTitle
                titleTemplate
                seoDescription
            }
        }
        site {
            siteMetadata {
                defaultImage: image
                siteUrl: url
                twitterUsername
            }
        }
    }
`

export default SEO
