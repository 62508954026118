// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-commissioned-tsx": () => import("./../../../src/pages/commissioned.tsx" /* webpackChunkName: "component---src-pages-commissioned-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-personal-tsx": () => import("./../../../src/pages/personal.tsx" /* webpackChunkName: "component---src-pages-personal-tsx" */),
  "component---src-templates-project-template-tsx": () => import("./../../../src/templates/ProjectTemplate.tsx" /* webpackChunkName: "component---src-templates-project-template-tsx" */),
  "component---src-templates-stream-template-tsx": () => import("./../../../src/templates/StreamTemplate.tsx" /* webpackChunkName: "component---src-templates-stream-template-tsx" */)
}

