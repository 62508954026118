import React from 'react'
import { ThemeToggler as GThemeToggler } from 'gatsby-plugin-dark-mode'

import './src/fonts/typography.css'
import App from './src/components/App'
import { ThemeProvider } from './src/context/ThemeContext'
import { getRandomInt } from './src/utils/algo'

export const wrapPageElement = ({ element, props }) => (
    <App {...props}>{element}</App>
)

export const SeedContext = React.createContext()

export const wrapRootElement = ({ element }) => {
    return (
        <SeedContext.Provider value={getRandomInt(1, 100)}>
            <GThemeToggler>
                {({ theme, toggleTheme }) => {
                    // Don't render anything at compile time. Deferring rendering until we
                    // know which theme to use on the client avoids incorrect initial
                    // state being displayed.
                    if (theme == null) {
                        return null
                    }
                    return (
                        <ThemeProvider
                            value={{
                                theme,
                                toggleTheme,
                            }}
                        >
                            {element}
                        </ThemeProvider>
                    )
                }}
            </GThemeToggler>
        </SeedContext.Provider>
    )
}
