export function getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
}

export function random(seed) {
    var x = Math.sin(seed++) * 10000
    return x - Math.floor(x)
}

export function shuffleArray(
    array,
    seed = getRandomInt(1, 100),
    deepClone = true
) {
    // Deep clone
    const _array = deepClone ? JSON.parse(JSON.stringify(array)) : array
    for (let i = _array.length - 1; i > 0; i--) {
        const j = Math.floor(random(seed) * (i + 1))
        ;[_array[i], _array[j]] = [_array[j], _array[i]]
        ++seed
    }
    return _array
}
