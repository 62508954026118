import { css } from 'styled-components'

const size = {
    smartphone: '320px',
    tablet: '720px',
    laptop: '1024px',
    desktop: '1300px',
}

export const mq = {
    smartphone: `@media (min-width: ${size.smartphone})`,
    tablet: `@media (min-width: ${size.tablet})`,
    laptop: `@media (min-width: ${size.laptop})`,
    desktop: `@media (min-width: ${size.desktop})`,
}

const BASE_REM_SIZES = {
    sm: 0.5,
    md: 0.75,
    lg: 1,
}

const BASE_PX_SIZES = {
    sm: 10,
    md: 20,
    lg: 30,
}

const getSizes = (sizes) => {
    // Support for passing just a single Int
    if (Number.isInteger(sizes) && sizes > 0) {
        return {
            sm: `${sizes * BASE_PX_SIZES.sm}px`,
            md: `${sizes * BASE_PX_SIZES.md}px`,
            lg: `${sizes * BASE_PX_SIZES.lg}px`,
        }
    }
    // Support for syntax [horizontal, vertical]
    else if (sizes.length === 2) {
        return {
            sm: `${sizes[0] * BASE_PX_SIZES.sm}px ${
                sizes[1] * BASE_PX_SIZES.sm
            }px`,
            md: `${sizes[0] * BASE_PX_SIZES.md}px ${
                sizes[1] * BASE_PX_SIZES.md
            }px`,
            lg: `${sizes[0] * BASE_PX_SIZES.lg}px ${
                sizes[1] * BASE_PX_SIZES.lg
            }px`,
        }
        // Else lets assume for now a case of 3 values in an array that represent the 3 sizes for each breakpoint
    } else {
        return {
            sm: sizes[0],
            md: sizes[1],
            lg: sizes[2],
        }
    }
}

type SpacingProperties =
    | 'padding'
    | 'padding-left'
    | 'padding-right'
    | 'padding-top'
    | 'padding-bottom'
    | 'margin'
    | 'margin-left'
    | 'margin-right'
    | 'margin-top'
    | 'margin-bottom'
    | 'column-gap'
    | 'row-gap'
/**
 * Returns responsive styles to be used in a styled component
 * TODO: Make it work with padding 1px 2px; syntax!
 */
export const spacingOld = (
    sizes: number | number[] = 1,
    property: SpacingProperties = 'padding'
) => {
    const { sm, md, lg } = getSizes(sizes)
    return css`
        ${property}: ${sm};
        ${mq.tablet} {
            ${property}: ${md};
        }
        ${mq.laptop} {
            ${property}: ${lg};
        }
    `
}

export const spacing = (
    sizes: number | number[] | [number[]] = 10,
    property: SpacingProperties = 'padding'
) => {
    let sm
    let md
    let lg
    if (Array.isArray(sizes) && sizes.length == 2) {
        sm = `${sizes[0][0]}px ${sizes[1][0]}px`
        md = `${sizes[0][1]}px ${sizes[1][1]}px`
        lg = `${sizes[0][2]}px ${sizes[1][2]}px`
    } else if (Array.isArray(sizes)) {
        sm = `${sizes[0]}px`
        md = `${sizes[1]}px`
        lg = `${sizes[2]}px`
    } else {
        sm = `${sizes}px`
        md = `${sizes}px`
        lg = `${sizes}px`
    }

    return css`
        ${property}: ${sm};
        ${mq.tablet} {
            ${property}: ${md};
        }
        ${mq.laptop} {
            ${property}: ${lg};
        }
    `
}
